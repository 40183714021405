import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import downloadURI from '../utils/downloadURI'
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';

const Do = () => {
	return (
		<L10nProvider>
			<Download />
		</L10nProvider>
	)
}

export const Download = () => {
	const t = useLocale()

	const handleDownload = (url, name) => {
		downloadURI(url , name)
	}

	return (
		<LayoutDefault>
			<section className={classes.sec}>
				<h2 className={classes.secTitle}>
					{t('download.title')}
				</h2>
				<div className={classes.secBlock}>
					<div className={classes.download}>
						<div className={classes.downloadLeft}>
							<div className={classes.downloadTitle}>
								LogDoc Portable
							</div>
							<div className={classes.downloadInfo}>
								{t('download.updated')} 25.08.2023
							</div>
						</div>
						<div className={classes.downloadRight}>
							<div className={classes.downloadSize}>
								17 МБ
							</div>
							<div
								onClick={() => handleDownload("/logdoc-portable-latest.tgz" , 'logdoc-portable-latest.tgz')}
								className={classes.downloadAction}
							/>
						</div>
					</div>
				</div>
				<div className={classes.secBlock}>
					<div className={classes.download}>
						<div className={classes.downloadLeft}>
							<div className={classes.downloadTitle}>
								LogDoc Community
							</div>
							<div className={classes.downloadInfo}>
								{t('download.updated')} 25.08.2023
							</div>
						</div>
						<div className={classes.downloadRight}>
							<div className={classes.downloadSize}>
								17 МБ
							</div>
							<div
								onClick={() => handleDownload("/logdoc-community-latest.tgz", 'logdoc-community-latest.tgz')}
								className={classes.downloadAction}
							/>
						</div>
					</div>
				</div>
			</section>
		</LayoutDefault>
	);
};

export default Do;
